import React, { useEffect, useRef, useState } from "react";
import CustomDialog from "../../../common/CustomDialog";
import { Form, Formik } from "formik";
import CustomInput from "../../../common/CustomInput";
import CustomButton from "../../../common/CustomButton";
import Spinner from "../../../common/Spinner";
import { newGameValidationSchema } from "../../../../constants/validate";
import { useSelector } from "react-redux";

const GameModal = ({
  open,
  onClose,
  onSubmit,
  onExistingGameInfo,
  loader,
  gameData,
  submitText = "Create Game",
}) => {
  const [gameLogo, setGameLogo] = useState(null);

  const fileInputRef = useRef(null);

  let auth = useSelector((state) => state.auth.me);
  auth = { role: 1 };

  const handleSubmit = (values, setValues) => {
    onSubmit(values);
    setGameLogo(null);
    setValues({
      gameName: "",
      gameDescription: "",
      gameLink: "",
      image: null,
    });
  };

  const handleFileChange = (event, values, setValues) => {
    const file = event.target.files[0];
    setGameLogo(URL.createObjectURL(file));
    setValues({ ...values, image: file });
  };

  const triggerFileInput = () => {
    fileInputRef.current && fileInputRef.current.click();
  };

  useEffect(() => {}, [gameData]);

  useEffect(() => {
    if (!open) {
      setGameLogo(null);
      if (gameData) setGameLogo(gameData.image);
    }
    if (gameData && !gameLogo) setGameLogo(gameData.image);
  }, [gameData, open]);

  return (
    <CustomDialog open={open} onClose={onClose} title={"New Game"}>
      <div className="game-modal">
        <Formik
          initialValues={
            gameData
              ? { ...gameData }
              : {
                  gameName: "",
                  gameDescription: "",
                  gameLink: "",
                  image: null,
                }
          }
          onSubmit={handleSubmit}
          validationSchema={newGameValidationSchema}
          validateOnChange={true}
        >
          {({ handleSubmit, errors, values, setValues }) => (
            <Form>
              <div>
                {onExistingGameInfo && (
                  <CustomButton
                    title="Use existing game info"
                    type="button"
                    onClick={onExistingGameInfo}
                    className={"custom-button"}
                  />
                )}
                {onExistingGameInfo && (
                  <div className="info-text">
                    Use the information of games from other fundraisers
                    {auth?.role !== "admin"
                      ? ". Only primary admin can add a different new game"
                      : ""}
                  </div>
                )}
                <CustomInput
                  name="gameName"
                  label={"Game Name"}
                  placeholder="Space Shooter"
                  className={"custom-input"}
                  disabled={auth?.role !== "admin"}
                />
                <CustomInput
                  name="gameDescription"
                  label={"Game Description"}
                  placeholder="Shoot meteorites and save planets!"
                  className={"custom-input"}
                  disabled={auth?.role !== "admin"}
                />
                <CustomInput
                  name="gameLink"
                  label={"Game Link"}
                  placeholder="https://www.space-shooter.com/"
                  className={"custom-input"}
                  disabled={auth?.role !== "admin"}
                />
                <div className="logo-label">Game Logo</div>
                <div className="image-wrapper">
                  {gameLogo && (
                    <img src={gameLogo} style={{ objectFit: "fill" }} />
                  )}
                  <CustomButton
                    title={gameLogo ? "Change" : "Upload"}
                    type={"button"}
                    className={"upload-button"}
                    onClick={triggerFileInput}
                    disabled={auth?.role !== "admin"}
                  />
                </div>
                <input
                  type="file"
                  accept="image/png, image/jpg, image/jpeg, image/webp"
                  ref={fileInputRef}
                  style={{ display: "none" }}
                  onChange={(e) => handleFileChange(e, values, setValues)}
                />
                <div className="submit-button">
                  {loader ? (
                    <Spinner />
                  ) : (
                    <CustomButton
                      title={submitText}
                      className={"submit"}
                      onClick={handleSubmit}
                      disabled={!gameLogo || Object.keys(errors).length > 0}
                    />
                  )}
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </CustomDialog>
  );
};

export default GameModal;
