import React, { useCallback, useEffect, useState } from "react";
import OtpVerification from "../../common/OtpVerification";

import { useLocation, useNavigate } from "react-router-dom";
import { Toaster } from "../../../constants";
import { resendOtp, verifyOtp } from "../../../apis/Auth";
import { useDispatch } from "react-redux";

const AuthVerify = () => {
  const [otp, setOtp] = useState(["", "", "", "", ""]);
  const [otpLoader, setOtpLoader] = useState(false);
  const [authData, setAuthData] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const handleOtpChange = (e, ind) => {
    if (isNaN(e.target.value)) return false;
    setOtp([...otp.map((d, index) => (index === ind ? e.target.value : d))]);
    if (e.target.nextSibling) {
      e.target.nextSibling.focus();
    }
  };

  const handleResendCode = async () => {
    try {
      await resendOtp(
        authData?.email,
        authData?.role,
        authData?.mode,
        authData?.verificationSelection
      );
      Toaster("success", "Otp has been sent successfully");
    } catch (error) {
      error = error?.response?.data?.message || "Otp could not be sent";
      Toaster("error", error);
    }
  };

  const handleVerify = useCallback(async () => {
    try {
      if (authData) {
        setOtpLoader(true);
        dispatch(
          verifyOtp(
            authData?.email,
            authData?.role,
            authData?.mode,
            authData?.verificationSelection,
            otp.join(""),
            setOtpLoader
          )
        );
        setTimeout(() => navigate(authData?.redirect), 1000);
        Toaster("success", "Otp has been verified successfully");
      }
    } catch (error) {
      let message =
        error.response?.data?.message || "Code could not be verified";
      setOtpLoader(false);
      Toaster("error", message);
    }
  }, [authData, otp]);

  const onKeyPress = useCallback(
    (event) => {
      if (otpLoader) return;
      if (event.key === "Enter") {
        event.preventDefault();
        handleVerify();
      }
    },
    [handleVerify, otpLoader]
  );

  useEffect(() => {
    if (location.state) {
      setAuthData(location.state);
    }
  }, [location]);

  useEffect(() => {
    document.addEventListener("keydown", onKeyPress);
    return () => {
      document.removeEventListener("keydown", onKeyPress);
    };
  }, [handleVerify, onKeyPress]);

  return (
    <div>
      <OtpVerification
        otp={otp}
        communicationMedia={authData?.phone}
        handleChange={handleOtpChange}
        header={"OTP Verification"}
        resendCode={handleResendCode}
        verify={handleVerify}
        loader={otpLoader}
      />
    </div>
  );
};

export default AuthVerify;
