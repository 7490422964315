import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { MdOutlineCancel } from "react-icons/md";
import { ReactComponent as BackButton } from "../../assets/Back Button.svg";

const CustomDialog = ({
  children,
  open = false,
  onClose,
  title = "",
  onBackButtonClick = null,
  hideCloseIcon = false,
  closeOnIconOnly = false,
}) => {
  return (
    <div className="custom-dialog-wrapper">
      <Dialog
        open={open}
        onClose={!closeOnIconOnly && onClose}
        className={`dialog-container ${
          closeOnIconOnly && open ? "blur-background" : ""
        }`}
      >
        <DialogTitle className="dialog-title">
          <div className="title-wrapper">
            {onBackButtonClick && (
              <BackButton onClick={onBackButtonClick} className="back-button" />
            )}
            <span className="title">{title}</span>
            {!hideCloseIcon && (
              <span className="icon" onClick={onClose}>
                <MdOutlineCancel />
              </span>
            )}
          </div>
        </DialogTitle>
        <DialogContent>{children}</DialogContent>
      </Dialog>
    </div>
  );
};

export default CustomDialog;
