import React from "react";
import ".././styles.scss";
import "react-datepicker/dist/react-datepicker.css";
import CustomInput from "../../../../common/CustomInput";
import { Form, Formik } from "formik";
import CustomButton from "../../../../common/CustomButton";
import {
  SingleCauseValidationSchema,
  GroupCauseValidationSchema,
} from "../../../../../constants/validate";
import CustomDialog from "../../../../common/CustomDialog";
import Spinner from "../../../../common/Spinner";

const CauseModal = ({
  fundraiserData = null,
  onSubmit,
  submitText = "Submit",
  onSkip = () => {},
  skipText = "Skip",
  loader,
  updateFundraiserData,
  open,
  onClose,
  title,
  onBack,
  groupAccessIndex = null,
}) => {
  const getCause = () => {
    let groupCause = [];
    for (let i = 0; i < fundraiserData?.groups?.length || 0; i += 1) {
      groupCause.push(fundraiserData.groups[i].groupCause);
    }
    const combinedCause = fundraiserData.fundRaisingCause;
    return { groupCause, combinedCause };
  };

  const noGroups =
    !fundraiserData?.groups || fundraiserData.groups.length === 0;

  const initialValue = getCause();

  const handleSubmit = (values) => {
    for (let i = 0; i < (fundraiserData?.groups?.length || 0); i += 1) {
      fundraiserData.groups[i].groupCause = values.groupCause[i];
    }
    fundraiserData.fundRaisingCause = values.combinedCause;
    updateFundraiserData(fundraiserData);
    onSubmit();
  };

  const handleSkip = (e) => {
    e.preventDefault();
    onSkip();
  };

  return (
    <CustomDialog
      open={open}
      onClose={onClose}
      title={title}
      onBackButtonClick={onBack}
      closeOnIconOnly
    >
      <div className="cause-modal">
        {noGroups ? (
          <Formik
            initialValues={initialValue}
            onSubmit={handleSubmit}
            validationSchema={SingleCauseValidationSchema}
          >
            {({ handleSubmit, setFieldValue, errors, values, setValues }) => (
              <Form>
                <div className="combined-cause">
                  <CustomInput
                    className={"custom-input"}
                    label={"Cause"}
                    name={"combinedCause"}
                    placeholder="Eg. New instruments, Supplies, etc."
                  ></CustomInput>
                </div>
                <div className="button-group">
                  {loader ? (
                    <Spinner />
                  ) : (
                    <CustomButton
                      className={"custom-button"}
                      title={submitText}
                      onClick={handleSubmit}
                    ></CustomButton>
                  )}
                  <CustomButton
                    className={"custom-button"}
                    title={skipText}
                    onClick={handleSkip}
                    disabled={loader}
                  ></CustomButton>
                </div>
              </Form>
            )}
          </Formik>
        ) : (
          <Formik
            initialValues={initialValue}
            onSubmit={handleSubmit}
            validationSchema={GroupCauseValidationSchema}
          >
            {({ handleSubmit, setFieldValue, errors, values, setValues }) => (
              <Form>
                <div className="combined-cause">
                  <CustomInput
                    className={"custom-input"}
                    label={"Combined Cause"}
                    name={"combinedCause"}
                    placeholder="Eg. New instruments, Supplies, etc."
                    disabled={groupAccessIndex !== null}
                  ></CustomInput>
                </div>
                <div class="line-with-text">
                  <span>Indiviual Cause</span>
                </div>
                {values.groupCause &&
                  values.groupCause.map((cause, index) => (
                    <div className="group-cause-wrapper">
                      <div className="heading">Group {index + 1}:</div>
                      <div className="group-name">
                        {fundraiserData.groups[index]?.name || ""}
                      </div>
                      <CustomInput
                        className={"custom-input"}
                        label={"Cause"}
                        name={`groupCause.${index}`}
                        placeholder="Eg. New instruments, Supplies, etc."
                        disabled={
                          groupAccessIndex !== null &&
                          groupAccessIndex !== index
                        }
                      ></CustomInput>
                    </div>
                  ))}
                <div className="button-group">
                  {loader ? (
                    <Spinner />
                  ) : (
                    <CustomButton
                      className={"custom-button"}
                      title={submitText}
                      onClick={handleSubmit}
                    ></CustomButton>
                  )}
                  <CustomButton
                    className={"custom-button"}
                    title={skipText}
                    onClick={handleSkip}
                    disabled={loader}
                  ></CustomButton>
                </div>
              </Form>
            )}
          </Formik>
        )}
      </div>
    </CustomDialog>
  );
};

export default CauseModal;
