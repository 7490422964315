import React, { useEffect, useState } from "react";
import "./styles.scss";
import UpIcon from "../../assets/Up.svg";
import DownIcon from "../../assets/Down.svg";
import { useField } from "formik";

const CustomCounter = ({
  label,
  className,
  onChange,
  countPostfix,
  initialValue = 1,
  max = null,
  ...rest
}) => {
  const [field, meta] = useField(rest);
  const [count, setCount] = useState(initialValue);

  useEffect(() => {
    onChange(count);
  }, [count]);

  return (
    <div className={`custom-counter-wrapper ${className}`}>
      <div className="label">{label}</div>
      <div className="inner-wrapper">
        <div className="display" {...rest} {...field}>
          {count} Week{count > 1 ? "s" : ""}
        </div>
        <div className="counter">
          <button
            onClick={(event) => {
              if (!max || count !== max) setCount(count + 1);
              event.preventDefault();
            }}
            disabled={count === max}
          >
            <img src={UpIcon} alt={"^"} />
          </button>
          <button
            onClick={(event) => {
              count !== 1 && setCount(count - 1);
              event.preventDefault();
            }}
            disabled={count === 1}
          >
            <img src={DownIcon} alt={"v"} />
          </button>
        </div>
      </div>
      {meta.error && meta.touched && (
        <p className="error-message">{meta.error}</p>
      )}
    </div>
  );
};

export default CustomCounter;
