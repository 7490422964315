import axios from "../AxiosInstance";

export const getAllFundraisersBasics = () =>
  new Promise((resolve, reject) => {
    axios
      .get(`/fundRaisingRoutes/getFundRaising`)
      .then((response) => {
        resolve(response.data.data);
      })
      .catch((err) => {
        reject(err);
      });
  });

export const getAllFundraisers = () =>
  new Promise((resolve, reject) => {
    axios
      .get(`/fundRaisingRoutes/getFundRaisingByRole`)
      .then((response) => {
        resolve(response.data.data);
      })
      .catch((err) => {
        reject(err);
      });
  });

export const createFundraiser = (body) =>
  new Promise((resolve, reject) => {
    axios
      .post(`/fundRaisingRoutes/createFundRaising`, body)
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });

export const fetchSimilarFundraisers = (fundRaisingName) =>
  new Promise((resolve, reject) => {
    axios
      .get(`/fundRaisingRoutes/getSimilarFundraisings`, {
        params: {
          fundRaisingName,
        },
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });

export const updateFundraiser = (body) =>
  new Promise((resolve, reject) => {
    axios
      .put(`/fundRaisingRoutes/updateFundRaising`, body, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });

export const deleteFundraiser = (fundRaisingId) =>
  new Promise((resolve, reject) => {
    axios
      .delete(
        `/fundRaisingRoutes/deleteFundRaising?fundRaisingId=${fundRaisingId}`
      )
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });

export const CreateGroups = (body) =>
  new Promise((resolve, reject) => {
    axios
      .post(`/groupRoutes/createGroups`, body)
      .then((response) => {
        resolve(response.data.data);
      })
      .catch((err) => {
        reject(err);
      });
  });

export const updateFundraisingStripe = (body) =>
  new Promise((resolve, reject) => {
    axios
      .post("fundRaisingRoutes/updateFundRaisingStripe", body)
      .then((response) => {
        resolve(response.data.data);
      })
      .catch((err) => {
        reject(err);
      });
  });

export const changeAdminPublicLinkStatus = (body) =>
  new Promise((resolve, reject) => {
    axios
      .put(`fundRaisingRoutes/updateAdminGeneralInvitationLink`, body)
      .then((response) => {
        resolve(response.data.data);
      })
      .catch((err) => {
        reject(err);
      });
  });

export const changeParticipantPublicLinkStatus = (body) =>
  new Promise((resolve, reject) => {
    axios
      .put(`fundRaisingRoutes/updateParticipantGeneralInvitationLink`, body)
      .then((response) => {
        resolve(response.data.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
