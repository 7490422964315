import React from "react";
import { Navigate, Outlet, useSearchParams } from "react-router-dom";
import { isLoggedIn } from "../../apis/Auth";

const ParticipantRoute = () => {
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");
  if (token) return <Outlet />;
  return isLoggedIn() ? <Outlet /> : <Navigate to="/participant/signup" />;
};

export default ParticipantRoute;
