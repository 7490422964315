import React, { useEffect, useState } from "react";
import "./styles.scss";
import img1 from "../../../assets/teamdasboardImg3.png";
import img2 from "../../../assets/teamdasboardImg1.png";
import img3 from "../../../assets/teamdasboardImg2.png";
import Badges from "../../../assets/badges1.png";
import SponsorImg1 from "../../../assets/sponsorImg1.png";
import SponsorImg2 from "../../../assets/donor2.png";
import SponsorImg3 from "../../../assets/donor3.png";
import CustomButton from "../../common/CustomButton";
import { GetTeamMemberDashboard } from "../../../apis/Team";
import { DASHBOARD_MODALS, Toaster } from "../../../constants";
import PaymentModal from "../Stripe/PaymentModal";
import TokenSelectorModal from "../../common/TokensSelectorModal";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import Game from "../../common/Game";

function FundraiserTeammemberDashboard() {
  const [getTeamMember, setGetTeamMember] = useState(null);
  const [activeModal, setActiveModal] = useState(null);
  const [games, setGames] = useState(null);
  const [fundriaser, setFundraiser] = useState(null);

  const { fundraiserId = null } = useParams();

  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");
  if (
    token &&
    (!localStorage.fundToken ||
      localStorage.fundToken !== decodeURIComponent(token))
  ) {
    localStorage.setItem("fundToken", decodeURIComponent(token));
  }

  const navigate = useNavigate();

  const sponsorsImg = [
    { img: SponsorImg1 },
    { img: SponsorImg2 },
    { img: SponsorImg3 },
    { img: SponsorImg3 },
  ];

  let badgeImg = Array.from({ length: 6 });
  let lineborder = Array.from({ length: 10 });

  // Get Info
  const getInfo = async (fundraiserId) => {
    if (!fundraiserId) return;
    try {
      const response = await GetTeamMemberDashboard(fundraiserId);
      setGetTeamMember(response?.data?.teamMember);
      setFundraiser(response?.data?.fundRaisers);
      setGames(response?.data?.games);
    } catch (error) {
      let errorMessage =
        error.response?.data?.message || "Dashboard could not be loaded";
      Toaster("error", errorMessage);
    }
  };

  const onModalClose = () => {
    if (activeModal.type === DASHBOARD_MODALS.PURCHASE_TOKENS) {
      // eslint-disable-next-line no-restricted-globals
      const confirmation = confirm("Close Payment?");
      if (!confirmation) return;
    }

    setActiveModal(null);
  };
  const handleTokenAmountSelection = (amount) => {
    setActiveModal({ type: DASHBOARD_MODALS.PURCHASE_TOKENS, amount });
  };

  useEffect(() => {
    getInfo(fundraiserId);
  }, [fundraiserId]);

  return (
    <div>
      <CustomButton
        title={"< Back to dashboard"}
        onClick={() => navigate("/teammember/dashboard")}
        className={"navigate-button"}
      />
      <div
        className="navigate-text"
        onClick={() => navigate("/teammember/dashboard")}
      >
        {"< Back to dashboard"}
      </div>
      <div className="team-dashboard-main-wrapper">
        <PaymentModal
          open={activeModal?.type === DASHBOARD_MODALS.PURCHASE_TOKENS}
          onClose={onModalClose}
          title={"Purchase Tokens"}
          tokens={activeModal?.amount}
          fundRaiserId={fundraiserId}
        />
        <TokenSelectorModal
          open={activeModal?.type === DASHBOARD_MODALS.TOKEN_AMOUNT_SELECTOR}
          onClose={onModalClose}
          onSubmit={handleTokenAmountSelection}
        />
        <div>
          <h1 className="dashboard-title">Dashboard</h1>
          <div className="team-dashboard-content-wrapper">
            <div className="content-first-wrapper">
              <h2 className="content-title">INFORMATION</h2>
              <h4 className="content-heading">
                FirstName :{" "}
                <span className="content-para">{getTeamMember?.firstName}</span>
              </h4>
              <h4 className="content-heading">
                LastName :{" "}
                <span className="content-para"> {getTeamMember?.lastName}</span>
              </h4>
              <h4 className="content-heading">
                Email :{" "}
                <span className="content-para">{getTeamMember?.email}</span>
              </h4>
              <h4 className="content-heading">
                Phone No :{" "}
                <span className="content-para">{getTeamMember?.phoneNo}</span>
              </h4>
              <h4 className="content-heading">
                Tokens :{" "}
                <span className="content-para">{getTeamMember?.tokens}</span>
              </h4>
              <h4 className="content-heading">
                Fundraiser Goal :{" "}
                <span className="content-para">
                  {"$" +
                    (fundriaser?.fundRaisingGoal ||
                      (fundriaser?.fundRaisingGoal === 0 ? 0 : "---"))}
                </span>
              </h4>
            </div>
          </div>
        </div>
        <div className="games-title">Games</div>
        {!games?.length && (
          <div className="games-info">
            {!games
              ? "Loading Games..."
              : "This fundraiser currently has no games"}
          </div>
        )}
        {games?.length && (
          <div className="games-wrapper">
            <div className="games">
              {games.map((game) => (
                <Game
                  data={game}
                  navigate={navigate}
                  fundraiserId={fundraiserId}
                  blockCompete={
                    !getTeamMember?.tokens || !getTeamMember?.tokens > 0
                  }
                />
              ))}
            </div>
          </div>
        )}
        {/* <div className="dashboard-img-wrapper">
        <img src={img1} alt="sponsorImg" className="sponsor-Img" />
        <div className="complete-btn-img-wrapper">
          <CustomButton title={"Complete"} className="complete-btn" />
          <CustomButton title={"Practics"} className="complete-btn-secondary" />
        </div>
        <img src={img2} alt="sponsorImg" className="sponsor-Img" />
        <div className="complete-btn-img-wrapper">
          <CustomButton title={"Complete"} className="complete-btn" />
          <CustomButton title={"Practics"} className="complete-btn-secondary" />
        </div>
        <img src={img3} alt="sponsorImg" className="sponsor-Img" />
        <div className="complete-btn-img-wrapper">
          <CustomButton title={"Complete"} className="complete-btn" />
          <CustomButton title={"Practics"} className="complete-btn-secondary" />
        </div>
      </div>
      <div className="complete-btn-main-wrapper">
        <div className="complete-btn-wrapper">
          <CustomButton title={"Complete"} className="complete-btn" />
          <CustomButton title={"Practics"} className="complete-btn" />
        </div>
        <div className="complete-btn-wrapper">
          <CustomButton title={"Complete"} className="complete-btn-secondary" />
          <CustomButton title={"Practics"} className="complete-btn-secondary" />
        </div>
        <div className="complete-btn-wrapper">
          <CustomButton title={"Complete"} className="complete-btn-secondary" />
          <CustomButton title={"Practics"} className="complete-btn-secondary" />
        </div>
      </div> */}

        <div className="Purchase-token-btn">
          <CustomButton
            title={"Purchase Token"}
            className="purchase-btn"
            onClick={() =>
              setActiveModal({
                type: DASHBOARD_MODALS.TOKEN_AMOUNT_SELECTOR,
              })
            }
          />
        </div>

        <h1 className="badge-title">Badges</h1>
        <h2 className="badge-para">4 0f 25 earned</h2>
        <div className="badge-img-wrapper">
          {badgeImg.map((img) => (
            <div className="badges-img-parent">
              <div>
                <img src={Badges} className="badge-imge" />{" "}
              </div>
            </div>
          ))}
        </div>
        <h1 className="badge-title">Sponsors</h1>
        <div className="sponsor-img-wrapper">
          {sponsorsImg.map((imges) => (
            <div className="sponsor-img-parent">
              <div>
                <img src={imges.img} className="donor-img" />{" "}
              </div>
            </div>
          ))}
        </div>
        <h1 className="badge-title">Donors</h1>
        <div className="donor-main-wrapper">
          <div className="donors-box-wrapper">
            {lineborder.map((lines) => (
              <div>
                <div className="donor-line"></div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default FundraiserTeammemberDashboard;
