import React, { useState } from "react";
import Navbar from "./Navbar";
import SideBar from "./SideBar";
import { leftSideBarItems, menuItems } from "../../constants";
import Footer from "./Footer";
import { Outlet } from "react-router-dom";

const CustomLayout = () => {
  const [openLeftSideBar, setOpenLeftSideBar] = useState(false);
  const [openRightSideBar, setOpenRightSideBar] = useState(false);

  const handleLeftSideBarToggle = (state) => setOpenLeftSideBar(state);
  const handleCloseLeftSideBar = () => setOpenLeftSideBar(false);

  const handleRightSideBarToggle = (state) => setOpenRightSideBar(state);
  const handleCloseRightSideBar = () => setOpenRightSideBar(false);
  return (
    <div className="layout-wrapper">
      <Navbar
        handleLeftSideBar={handleLeftSideBarToggle}
        openLeftSideBar={openLeftSideBar}
        handleRightBar={handleRightSideBarToggle}
        openRightSideBar={openRightSideBar}
      />
      <SideBar
        anchor={"left"}
        open={openLeftSideBar}
        menuItems={menuItems}
        items={leftSideBarItems}
        handleClose={handleCloseLeftSideBar}
        menuName={"Menu"}
      />
      <SideBar
        anchor={"right"}
        open={openRightSideBar}
        menuItems={menuItems}
        items={leftSideBarItems}
        handleClose={handleCloseRightSideBar}
        menuName={"User Profile"}
      />
      <div className="body-wrapper">
        <Outlet />
      </div>
      <Footer />
    </div>
  );
};

export default CustomLayout;
