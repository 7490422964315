import { useEffect, useState } from "react";
import { getAllGames } from "../../../../apis/Game";
import CustomDialog from "../../../common/CustomDialog";
import Spinner from "../../../common/Spinner";
import { findMessage, Toaster } from "../../../../constants";

const ExistingGameModal = ({ open, onClose, fundraisingId, onSubmit }) => {
  const [loader, setLoader] = useState(false);

  const [games, setGames] = useState(null);

  const handleFetchGames = async () => {
    setLoader(true);
    try {
      const games = await getAllGames();

      const myGames = games.filter(
        (game) => game.fundRaiserId === fundraisingId
      );
      const myGameLinks = new Set(myGames.map((game) => game.gameLink));

      // Following games will appear: not in fundraising, not having same gamelink as any of fundraising games and are the first one to appear with same gameLink as other ones or with unique gameLink
      const uniqueGames = Array.from(
        new Map(
          games
            .filter((game) => game.fundRaiserId !== fundraisingId)
            .map((game) => [
              game.gameLink,
              {
                gameName: game.gameName,
                gameDescription: game.gameDescription,
                gameLink: game.gameLink,
                image: game.image,
              },
            ])
        ).values()
      ).filter((game) => !myGameLinks.has(game.gameLink));

      setGames(uniqueGames);
      setLoader(false);
    } catch (e) {
      setLoader(false);
      Toaster("error", findMessage(e, "Error fetching games!"));
    }
  };

  useEffect(() => {
    if (!open) setTimeout(() => setGames(null), 500);
    if (open) handleFetchGames();
  }, [open]);

  return (
    <CustomDialog open={open} onClose={onClose} title={"Select Game"}>
      <div className="existing-game-modal">
        {loader && <Spinner />}
        {games?.length === 0 && (
          <div className="error">No existing games found!</div>
        )}
        {!games?.length
          ? null
          : games.map((game) => (
              <div className="game-wrapper" onClick={() => onSubmit(game)}>
                <img src={game.image} alt="" className="game-image" />
                <div className="game-name">{game.gameName}</div>
              </div>
            ))}
      </div>
    </CustomDialog>
  );
};

export default ExistingGameModal;
