import React, { useEffect, useRef, useState } from "react";
import CustomDialog from "../../../../common/CustomDialog";
import CustomInput from "../../../../common/CustomInput";
import CustomButton from "../../../../common/CustomButton";
import { Form, Formik } from "formik";
import { newSponsorValidationSchema } from "../../../../../constants/validate";
import Spinner from "../../../../common/Spinner";
import CustomCheckBox from "../../../../common/CustomCheckBox";

const SponsorModal = ({
  open,
  onClose,
  onSubmit,
  loader,
  sponsorData,
  title = "New Sponsor",
  submitText = "Create Sponsor",
}) => {
  const [sponsorLogo, setSponsorLogo] = useState(null);

  const fileInputRef = useRef(null);

  const handleSubmit = (values, setValues) => {
    onSubmit(values);
    setSponsorLogo(null);
    setValues({
      firstName: "",
      lastName: "",
      businessName: "",
      websiteUrl: "",
      businessImage: null,
      isFeatured: false,
    });
  };

  const handleFileChange = (event, values, setValues) => {
    const file = event.target.files[0];
    setSponsorLogo(URL.createObjectURL(file));
    setValues({ ...values, businessImage: file });
  };

  const triggerFileInput = () => {
    fileInputRef.current && fileInputRef.current.click();
  };

  useEffect(() => {
    if (!open) {
      setSponsorLogo(null);
      if (sponsorData) setSponsorLogo(sponsorData.businessImage);
    }
    if (sponsorData && !sponsorLogo) setSponsorLogo(sponsorData.businessImage);
  }, [sponsorData, open]);

  return (
    <CustomDialog open={open} onClose={onClose} title={title}>
      <div className="game-modal">
        <Formik
          initialValues={
            sponsorData
              ? { ...sponsorData }
              : {
                  firstName: "",
                  lastName: "",
                  businessName: "",
                  websiteUrl: "",
                  businessImage: null,
                  isFeatured: false,
                }
          }
          onSubmit={handleSubmit}
          validationSchema={newSponsorValidationSchema}
          validateOnChange={true}
        >
          {({ handleSubmit, errors, values, setValues }) => (
            <Form>
              <div>
                <CustomInput
                  name="firstName"
                  label={"First Name"}
                  placeholder="Wade"
                  className={"custom-input"}
                />
                <CustomInput
                  name="lastName"
                  label={"Last Name"}
                  placeholder="Johnson"
                  className={"custom-input"}
                />
                <CustomInput
                  name="businessName"
                  label={"Business Name"}
                  placeholder="Wally Plumbing"
                  className={"custom-input"}
                />
                <CustomInput
                  name="websiteUrl"
                  label={"Website URL"}
                  placeholder="https://www.topscorefundraising.com/"
                  className={"custom-input"}
                />
                <CustomCheckBox
                  title={"Featured Sponsor"}
                  className={"sponsor-check"}
                  checked={values.isFeatured}
                  onClick={() =>
                    setValues({ ...values, isFeatured: !values.isFeatured })
                  }
                />
                <div className="logo-label">Sponsor Logo</div>
                <div className="image-wrapper">
                  {sponsorLogo && <img src={sponsorLogo} alt="logo" />}
                  <CustomButton
                    title={sponsorLogo ? "Change" : "Upload"}
                    type={"button"}
                    className={"upload-button"}
                    onClick={triggerFileInput}
                  />
                </div>
                <input
                  type="file"
                  accept="image/png, image/jpg, image/jpeg"
                  ref={fileInputRef}
                  style={{ display: "none" }}
                  onChange={(e) => handleFileChange(e, values, setValues)}
                />
                <div className="submit-button">
                  {loader ? (
                    <Spinner />
                  ) : (
                    <CustomButton
                      title={submitText}
                      className={"submit"}
                      onClick={handleSubmit}
                      disabled={!sponsorLogo || Object.keys(errors).length > 0}
                    />
                  )}
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </CustomDialog>
  );
};

export default SponsorModal;
