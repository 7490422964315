import React from "react";
import { Form, Formik } from "formik";
import "react-datepicker/dist/react-datepicker.css";
import CustomDialog from "../../../common/CustomDialog";
import { newTeammemberValidationSchema } from "../../../../constants/validate";
import CustomInput from "../../../common/CustomInput";
import CustomPhone from "../../../common/CustomPhone";
import Spinner from "../../../common/Spinner";
import CustomButton from "../../../common/CustomButton";

const InviteTeammemberModal = ({
  onSubmit,
  loader,
  open,
  onClose,
  StartChild = () => null,
  hideForm = false,
}) => {
  const handleFormSubmit = (values, actions) => {
    onSubmit(values);
  };

  const initialValues = {
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
  };

  return (
    <CustomDialog open={open} onClose={onClose} title={"New Teammember"}>
      <div className="invite-participant-modal">
        <div className="info-modal-wrapper">
          <StartChild />
          {!hideForm && (
            <Formik
              initialValues={initialValues}
              onSubmit={handleFormSubmit}
              validateOnChange={true}
              validationSchema={newTeammemberValidationSchema}
            >
              {({
                handleSubmit,
                setFieldValue,
                errors,
                setErrors,
                values,
                setValues,
              }) => (
                <Form>
                  <div className="form-section">
                    <CustomInput
                      label={"First Name"}
                      name="firstName"
                      className={"input"}
                      placeholder={"Bob"}
                    />
                    <CustomInput
                      label={"Last Name"}
                      name="lastName"
                      className={"input"}
                      placeholder={"Smith"}
                    />
                    <CustomInput
                      label={"Email Address"}
                      name="email"
                      className={"input"}
                      placeholder={"BobSmith123@gmail.com"}
                    />
                    <CustomPhone
                      label={"Phone (optional)"}
                      name="phoneNumber"
                      className={"input"}
                      placeholder={"XXXX-XXXX-X"}
                    />

                    <div className="button-group">
                      {loader ? (
                        <Spinner />
                      ) : (
                        <CustomButton
                          title={"Confirm"}
                          onClick={handleSubmit}
                          className={"submit-button"}
                          disabled={Object.keys(errors).length > 0}
                        />
                      )}
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          )}
        </div>
      </div>
    </CustomDialog>
  );
};

export default InviteTeammemberModal;
