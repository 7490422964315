import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "./styles.scss";
import { getGame } from "../../../apis/Game";
import { GAMEEVENTS, Toaster } from "../../../constants";
import { useSelector } from "react-redux";
const Play = ({ mode }) => {
  const [state, setState] = useState("Loading");
  const [game, setGame] = useState(null);
  const iframeRef = useRef(null);

  const navigate = useNavigate();

  const { fundraiserId, gameId } = useParams();

  const auth = useSelector((state) => state.auth.me);

  useEffect(() => {
    if (!gameId) return;
    getGame(gameId).then((game) => {
      setGame(game);
    });
  }, [gameId]);

  useEffect(() => {
    const handleListenMessage = (event) => {
      if (event.data === GAMEEVENTS.PLAYCANVAS_READY) {
        setState("Ready");
      }
      if (event.data === GAMEEVENTS.NO_TOKENS) {
        setState("No_Tokens");
      }
      if (event.data === GAMEEVENTS.EXIT_GAME) {
        setTimeout(
          () => navigate(`/${auth?.role}/dashboard/` + fundraiserId),
          1000
        );
        Toaster("success", "Thanks for playing!");
      }
    };

    window.addEventListener("message", handleListenMessage);

    return () => {
      window.removeEventListener("message", handleListenMessage);
    };
  }, [auth]);

  useEffect(() => {
    if (state === "Ready" && iframeRef.current) {
      const gameData = {
        fundRaisingID: fundraiserId,
        gameID: gameId,
        gameMode: mode,
        token: localStorage.fundToken,
      };
      iframeRef.current.contentWindow?.postMessage(gameData, "*");
      console.log("Data sent to the game:", gameData);
    }
  }, [state, fundraiserId, gameId, mode]);
  return (
    <div
      className={`wrapper ${
        state === "Loading"
          ? "loading-border"
          : state === "Ready"
          ? "loaded-border"
          : "error-border"
      }`}
    >
      {game && (
        <iframe
          ref={iframeRef}
          src={game.gameLink}
          className="game-iframe"
          title="Game"
          width="100%"
          height="100%"
        />
      )}
    </div>
  );
};

export default Play;
