import * as Yup from "yup";

// ADMIN FORMS VALIDATION START

export const adminSignUpValidation = Yup.object().shape({
  firstName: Yup.string().required("First Name is required*").trim(),
  lastName: Yup.string().required("Last Name is required*").trim(),
  phoneNo: Yup.string().required("Phone is required*").trim(),
  email: Yup.string()
    .email("It must be a valid email")
    .required("Email is required*")
    .trim(),
  reEmail: Yup.string()
    .email("It must be a valid email")
    .required("Email is required*")
    .oneOf([Yup.ref("email"), null], "Emails do not match")
    .trim(),
  password: Yup.string()
    .required("Password is required*")
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
      " Password must be at least 8 characters long and contain at least one letter, one number and a special Character."
    )
    .trim(),
  rePassword: Yup.string()
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
      "Password must be at least 8 characters long and contain at least one letter, one number and a special Character."
    )
    .trim()
    .required("Confirm password is required*")
    .oneOf([Yup.ref("password"), null], "Passwords do not match"),
  checks: Yup.object({
    isPrivacyPolicy: Yup.boolean().required(),
    isTerms: Yup.boolean().required(),
  }).test("allTrue", "All checks must be true", function (value) {
    const { isPrivacyPolicy, isTerms } = value || {};
    return isPrivacyPolicy && isTerms;
  }),
});

export const adminLoginValidationSchema = Yup.object().shape({
  email: Yup.string()
    .email("It must be a valid email")
    .required("Email is required*")
    .trim(),
  password: Yup.string().required("Password is required*").trim(),
});

// ADMIN FORMS VALIDATION END

export const adminPermissionValidationSchema = Yup.object().shape({
  firstName: Yup.string().required("First Name is required*").trim(),
  lastName: Yup.string().required("Last Name is required*").trim(),
  email: Yup.string()
    .email("It must be a valid email")
    .required("Email is required*")
    .trim(),
  phoneNo: Yup.string().required("Phone is required*").trim(),
});

// Participants FORMS VALIDATION START

export const participantsSignUpValidation = Yup.object().shape({
  firstName: Yup.string().required("First Name is required*").trim(),
  lastName: Yup.string().required("Last Name is required*").trim(),
  fundraiser: Yup.string().required("Fundraiser is required*").trim(),
  email: Yup.string()
    .email("It must be a valid email")
    .required("Email is required*")
    .trim(),
  reEmail: Yup.string()
    .email("It must be a valid email")
    .required("Email is required*")
    .oneOf([Yup.ref("email"), null], "Emails do not match")
    .trim(),
  password: Yup.string()
    .required("Password is required*")
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
      " Password must be at least 8 characters long and contain at least one letter, one number and a special Character."
    )
    .trim(),
  phone: Yup.string().required("Phone Number is required*").trim(),
  rePassword: Yup.string()
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
      "Password must be at least 8 characters long and contain at least one letter, one number and a special Character."
    )
    .trim()
    .required("Confirm password is required*")
    .oneOf([Yup.ref("password"), null], "Passwords do not match"),
  checks: Yup.object({
    isPrivacyPolicy: Yup.boolean().required(),
    isTerms: Yup.boolean().required(),
  }).test("allTrue", "All checks must be true", function (value) {
    const { isPrivacyPolicy, isTerms } = value || {};
    return isPrivacyPolicy && isTerms;
  }),
});

export const participantsSignUpByLinkValidation = Yup.object().shape({
  firstName: Yup.string().required("First Name is required*").trim(),
  lastName: Yup.string().required("Last Name is required*").trim(),
  email: Yup.string()
    .email("It must be a valid email")
    .required("Email is required*")
    .trim(),
  password: Yup.string()
    .required("Password is required*")
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
      " Password must be at least 8 characters long and contain at least one letter, one number and a special Character."
    )
    .trim(),
  phone: Yup.string().required("Phone Number is required*").trim(),
  rePassword: Yup.string()
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
      "Password must be at least 8 characters long and contain at least one letter, one number and a special Character."
    )
    .trim()
    .required("Confirm password is required*")
    .oneOf([Yup.ref("password"), null], "Passwords do not match"),
  checks: Yup.object({
    isPrivacyPolicy: Yup.boolean().required(),
    isTerms: Yup.boolean().required(),
  }).test("allTrue", "All checks must be true", function (value) {
    const { isPrivacyPolicy, isTerms } = value || {};
    return isPrivacyPolicy && isTerms;
  }),
});
// Participants FORMS VALIDATION END

//Fundraiser FORMS VALIDATION START

export const fundraiserValidationSchema = Yup.object().shape({
  name: Yup.string().required("Name is required*").trim(),
  address: Yup.string().required("Address is required*").trim(),
  goal: Yup.string().required("Goal is required").trim(),
  city: Yup.string().required("City is required*").trim(),
  state: Yup.string().required("State is required*").trim(),
  zipCode: Yup.string().required("Zip code is required*").trim(),
  taxId: Yup.string().trim(),
  startDate: Yup.string().required("startDate is required*").trim(),
  endDate: Yup.string().required("End Date is required").trim(),
  routingNumber: Yup.string().required("Routing number is required*").trim(),
  accountNumber: Yup.string().required("Account Number is required*").trim(),
  verifyAccountNumber: Yup.string()
    .required("Verify Account number is required*")
    .trim()
    .oneOf([Yup.ref("accountNumber"), null], "Account Number does not match"),
});

// GROUPS FORMS VALIDATION START

export const groupModalValidation = Yup.object().shape({
  name: Yup.string().required("Group Name is required*").trim(),
  routingNumber: Yup.string().required("Routing Number is required*").trim(),
  bankAccountNumber: Yup.string()
    .required("Bank Account Number is required*")
    .trim(),
  confirmAccountNumber: Yup.string()
    .required("Confirm Account Number is required*")
    .oneOf([Yup.ref("bankAccountNumber"), null], "Account numbers do not match")
    .trim(),
  groupTotalDonations: Yup.string()
    .required("Confirm Account Number is required*")
    .trim(),
});

// TeamSignUpValidation  FORM VALIDATION START
export const TeamSignUpValidation = Yup.object().shape({
  firstName: Yup.string().required("First Name is required*").trim(),
  lastName: Yup.string().required("Last Name is required*").trim(),
  phoneNo: Yup.string().required("Phone is required*").trim(),
  email: Yup.string()
    .email("It must be a valid email")
    .required("Email is required*")
    .trim(),
  guardianEmail: Yup.string().email("It must be a valid email").trim(),
  password: Yup.string()
    .required("Password is required*")
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
      " Password must be at least 8 characters long and contain at least one letter, one number and a special Character."
    )
    .trim(),
  confirmPassword: Yup.string()
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
      "Password must be at least 8 characters long and contain at least one letter, one number and a special Character."
    )
    .trim()
    .required("Confirm password is required*")
    .oneOf([Yup.ref("password"), null], "Passwords do not match"),
  checks: Yup.object({
    isAuth: Yup.boolean().required(),
    isPrivacyPolicy: Yup.boolean().required(),
    isTerms: Yup.boolean().required(),
  }).test("allTrue", "All checks must be true", function (value) {
    const { isAuth, isPrivacyPolicy, isTerms } = value || {};
    return isAuth && isPrivacyPolicy && isTerms;
  }),
});

// TeamSignUpValidation  FORM VALIDATION END

// Fundraiser Info Validation
export const fundraiserInfoValidationSchema = Yup.object().shape({
  name: Yup.string().required("Name is required*").trim(),
  address: Yup.string().required("Address is required*").trim(),
  city: Yup.string().required("City is required*").trim(),
  state: Yup.string().required("State is required*").trim(),
  zipCode: Yup.string().required("Zip code is required*").trim(),
  taxId: Yup.string().trim(),
  startDate: Yup.string()
    .required("startDate is required*")
    .trim()
    .matches(/^\d{4}-\d{2}-\d{2}$/, "Date must be in the format YYYY-MM-DD")
    .test("is-future", "Start date cannot be a past date", (value) => {
      if (!value) return false;
      const startDate = new Date(value);
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      return startDate >= today;
    }),
  endDate: Yup.string().required("End Date is required").trim(),
  checks: Yup.object()
    .shape({
      isAuthorizedAdmin: Yup.boolean().oneOf([true], "Must be checked"),
      isPrivacyPolicy: Yup.boolean().oneOf([true], "Must be checked"),
      isTerms: Yup.boolean().oneOf([true], "Must be checked"),
    })
    .test("all-true", "All checkboxes must be checked", (value) => {
      return value.isAuthorizedAdmin && value.isPrivacyPolicy && value.isTerms;
    }),
});

export const GroupsValidationSchema = Yup.object().shape({
  groups: Yup.array()
    .of(
      Yup.object().shape({
        name: Yup.string().required("Group name is required"),
        admin: Yup.string(),
      })
    )
    .test("unique-names", "Group names must be unique", function (value) {
      if (!value || value.length <= 1) return true;
      const seenNames = new Set();
      for (let i = 0; i < value.length; i++) {
        const name = value[i].name;
        if (seenNames.has(name)) {
          return false;
        }
        seenNames.add(name);
      }
      return true;
    }),
});

export const SingleGoalValidationSchema = Yup.object().shape({
  fundraiserGoal: Yup.number()
    .required("Goal is required")
    .min(10, "Goal must be at least $10"),
});

export const ManualDonationValidationSchema = Yup.object().shape({
  amount: Yup.number()
    .required("Amount is required")
    .min(1, "Amount must be at least $1"),
  comments: Yup.string(),
});

export const GroupGoalValidationSchema = Yup.object().shape({
  fundraiserGoal: Yup.number()
    .nullable()
    .min(10, "Goal must be at least $10")
    .test(
      "at-least-one",
      "Either fundraising goal or all of the group goals are required",
      (_, context) => {
        const { fundraiserGoal, groupGoals } = context.parent;
        const allGroupGoalsValid =
          groupGoals &&
          groupGoals.every((goal) => goal !== null && goal !== undefined);
        return (
          (fundraiserGoal !== null && fundraiserGoal !== undefined) ||
          (groupGoals && groupGoals.length > 0 && allGroupGoalsValid)
        );
      }
    ),
  groupGoals: Yup.array().of(Yup.number().nullable()),
});

export const SingleCauseValidationSchema = Yup.object().shape({
  combinedCause: Yup.string()
    .required("Cause is required")
    .min(2, "Cause must be at least 2 characters")
    .max(100, "Cause must be at most 100 characters"),
});

export const GroupCauseValidationSchema = Yup.object().shape({
  combinedCause: Yup.string()
    .min(2, "Cause must be at least 2 characters")
    .max(100, "Cause must be at most 100 characters")
    .nullable()
    .test(
      "at-least-one",
      "Either fundraising cause or all of the group cause are required",
      (_, context) => {
        const { combinedCause, groupCause } = context.parent;
        const allGroupCauseValid =
          groupCause &&
          groupCause.every((cause) => cause !== null && cause !== undefined);
        return (
          (combinedCause !== null && combinedCause !== undefined) ||
          (groupCause && groupCause.length > 0 && allGroupCauseValid)
        );
      }
    ),
  groupCause: Yup.array().of(
    Yup.string()
      .min(2, "Cause must be at least 2 characters")
      .max(100, "Cause must be at most 100 characters")
      .nullable()
  ),
});

export const GroupNameValidationSchema = Yup.object().shape({
  groupName: Yup.string()
    .required("Group name is required")
    .min(2, "Group name must be at least 2 characters")
    .max(100, "Group name must be at most 100 characters"),
});

export const GroupStripeValidationSchema = Yup.object().shape({
  stripe: Yup.string().required("Stripe account ID is required"),
  isVerified: Yup.boolean().oneOf([true], "Verify your stripe ID"),
});

export const newParticipantValidationSchema = Yup.object().shape({
  firstName: Yup.string().required("First name is required*").trim(),
  lastName: Yup.string().required("Last name is required*").trim(),
  email: Yup.string()
    .required("Email is required*")
    .email("Invalid email format*")
    .trim(),
  phone: Yup.string().trim(),
});

export const newTeammemberValidationSchema = Yup.object().shape({
  firstName: Yup.string().required("First name is required*").trim(),
  lastName: Yup.string().required("Last name is required*").trim(),
  email: Yup.string()
    .required("Email is required*")
    .email("Invalid email format*")
    .trim(),
  phone: Yup.string().trim(),
});

export const contactValidationSchema = Yup.object().shape({
  fullName: Yup.string().required("Name is required*").trim(),
  email: Yup.string()
    .required("Email is required*")
    .email("Invalid email format*")
    .trim(),
  phoneNumber: Yup.string()
    .trim()
    .required("Phone number is required*")
    .min(4, "Phone number must be at least 4 digits long"),
});

export const newGroupValidationSchema = Yup.object().shape({
  groupName: Yup.string().required("Group Name is required*").trim(),
  adminEmail: Yup.string().trim(),
  groupCause: Yup.string().required("Group Cause is required*").trim(),
  groupTarget: Yup.string().required("Group goal is required!"),
});

export const shareTokensValidationSchema = Yup.object().shape({
  tokens: Yup.number()
    .min(1, "You must share at least 1 token")
    .integer("Can't share partial tokens")
    .required("Amount is required"),
});

export const newStripeAccountValidationSchema = Yup.object().shape({
  name: Yup.string().required("Account Name is required!"),
  // businessType: Yup.string().required("Account Type is required!"),
  // firstName: Yup.string().required("First Name is required!"),
  // lastName: Yup.string().required("Last Name is required!"),
  // businessName: Yup.string().required("Business Name is required!"),
});

export const tokenPurchaseAmountValidationSchema = Yup.object().shape({
  amount: Yup.number()
    .required("Amount is required")
    .integer("Amount must be a whole number")
    .typeError("Amount must be a number")
    .positive("Amount must be positive"),
});

export const newGameValidationSchema = Yup.object().shape({
  gameName: Yup.string().required("Game Name is required*").trim(),
  gameDescription: Yup.string()
    .trim()
    .required("Game description is required")
    .min(10, "Game description must be at least 10 characters"),
  gameLink: Yup.string().required("Game link is required*").trim(),
});

export const newSponsorValidationSchema = Yup.object().shape({
  firstName: Yup.string().required("First name is required"),
  lastName: Yup.string().required("Last name is required"),
  businessName: Yup.string().required("Business name is required"),
  websiteUrl: Yup.string()
    .url("Must be a valid URL")
    .required("Website URL is required"),
});

export const donationValidationSchema = Yup.object({
  amount: Yup.number()
    .min(5, "Amount must be at least $5")
    .test(
      "is-multiple-of-5",
      "Amount must be a multiple of 5",
      (value) => value % 5 === 0
    )
    .required("Amount is required"),

  type: Yup.string()
    .oneOf(["individual", "business"], "Invalid type")
    .required("Type is required"),

  firstName: Yup.string().required("First name is required"),
  lastName: Yup.string().required("Last name is required"),

  businessName: Yup.string().when("type", {
    is: "business",
    then: () => Yup.string().required("Business name is required"),
    otherwise: () => Yup.string(),
  }),

  websiteUrl: Yup.string()
    .url("Must be a valid URL")
    .when("type", {
      is: "business",
      then: () => Yup.string().required("Website URL is required"),
      otherwise: () => Yup.string(),
    }),

  image: Yup.mixed().when("type", {
    is: "business",
    then: () => Yup.mixed().required("Logo is required"),
    otherwise: () => Yup.mixed(),
  }),
});
