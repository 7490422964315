import React, { useEffect, useRef } from "react";
import "./styles.scss";
import { useField } from "formik";
import intlTelInput from "intl-tel-input";
import "intl-tel-input/build/css/intlTelInput.css";

const CustomPhone = ({ label, className, onChange, ...rest }) => {
  const [field, meta, helpers] = useField(rest);

  const inputRef = useRef(null);

  const intlRef = useRef(null);

  useEffect(() => {
    setTimeout(() => {
      if (!inputRef.current) return;
      intlRef.current = intlTelInput(inputRef.current, {
        initialCountry: "us",
      });
    }, 0);
  }, [inputRef]);

  const handleChange = (e) => {
    if (
      e.target.value.startsWith(
        "+" + intlRef.current.getSelectedCountryData().dialCode
      )
    ) {
      helpers.setValue(e.target.value);
      return;
    }
    if (!intlRef.current.getSelectedCountryData().dialCode) return;
    helpers.setValue("+" + intlRef.current.getSelectedCountryData().dialCode);
    if (onChange) {
      onChange(e);
    }
  };

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.addEventListener("input", handleChange);
      inputRef.current.addEventListener("countrychange", handleChange);
    }

    return () => {
      if (inputRef.current) {
        inputRef.current.removeEventListener("input", handleChange);
        inputRef.current.addEventListener("countrychange", handleChange);
      }
    };
  }, [inputRef.current]);

  return (
    <div className={`custom-date-wrapper ${className}`}>
      <div className="label">{label}</div>
      <input {...rest} {...field} ref={inputRef} />
      {meta.error && meta.touched && (
        <p className="error-message">{meta.error}</p>
      )}
    </div>
  );
};

export default CustomPhone;
