import React, { useMemo } from "react";
import ".././styles.scss";
import CustomInput from "../../../../common/CustomInput";
import { Form, Formik } from "formik";
import CustomButton from "../../../../common/CustomButton";
import {
  FormControlLabel,
  FormGroup,
  Grid,
  useMediaQuery,
} from "@mui/material";
import { fundraiserInfoValidationSchema } from "../../../../../constants/validate";
import "react-datepicker/dist/react-datepicker.css";
import CustomCounter from "../../../../common/CustomCounter";
import { useTheme } from "@mui/material/styles";
import CustomCheckBox from "../../../../common/CustomCheckBox";
import CustomDialog from "../../../../common/CustomDialog";
import Spinner from "../../../../common/Spinner";

function calculateWeeks(startDate, endDate) {
  const isValidDate = (date) => {
    return /^\d{4}-\d{2}-\d{2}$/.test(date);
  };

  if (isValidDate(startDate) && isValidDate(endDate)) {
    const start = new Date(startDate);
    const end = new Date(endDate);

    const timeDiff = Math.abs(end - start);
    const daysDiff = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));
    const weeksDiff = Math.ceil(daysDiff / 7);

    return weeksDiff;
  } else {
    return 3;
  }
}

const FundraiserInfoModal = ({
  fundraiserData = null,
  title = "New Fundraiser",
  onSubmit,
  loader,
  updateFundraiserData,
  openPreviousFundraiserSelector,
  open,
  onClose,
}) => {
  const handleFormSubmit = (values, actions) => {
    updateFundraiserData({ ...fundraiserData, ...values });
    onSubmit();
  };

  const initialValues = useMemo(() => {
    return (
      fundraiserData || {
        name: "",
        address: "",
        city: "",
        state: "",
        zipCode: "",
        startDate: new Date(),
        endDate: new Date(),
        routingNumber: "",
        accountNumber: "",
        taxId: "",
        verifyAccountNumber: "",
        checks: {
          isAuthorizedAdmin: false,
          isPrivacyPolicy: false,
          isTerms: false,
        },
      }
    );
  }, [fundraiserData]);

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.down("md"));

  const handleCheckBoxChange = (setValues, values, name, value) => {
    setValues({ ...values, checks: { ...values.checks, [name]: value } });
  };

  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const handleCounterChange = (setValues, values, value) => {
    if (!values.startDate) return;
    let endDate = new Date(values.startDate);
    endDate.setDate(endDate.getDate() + 7 * value);
    endDate = formatDate(endDate);

    setValues({
      ...values,
      endDate: endDate,
    });
  };

  const handleStartDateChange = (e, setValues, values) => {
    const beforeDate = new Date(values.startDate);
    const afterDate = new Date(e.currentTarget.value);
    const endDate = new Date(values.endDate);

    const difference = (afterDate - beforeDate) / (1000 * 60 * 60 * 24); // Days difference
    const newEndDate = new Date(
      endDate.setDate(endDate.getDate() + difference)
    );

    const formattedNewEndDate = newEndDate.toISOString().split("T")[0];

    setValues({
      ...values,
      startDate: e.currentTarget.value,
      endDate: formattedNewEndDate,
    });
  };

  return (
    <CustomDialog open={open} onClose={onClose} title={""} closeOnIconOnly>
      <div className="fundraiser-info-modal">
        {openPreviousFundraiserSelector && (
          <CustomButton
            title={"Use Previous Fundraiser Information"}
            onClick={openPreviousFundraiserSelector}
          ></CustomButton>
        )}
        <div className="title">{title}</div>
        <div className="info-modal-wrapper">
          <Formik
            initialValues={initialValues}
            onSubmit={handleFormSubmit}
            validateOnChange={true}
            validationSchema={fundraiserInfoValidationSchema}
          >
            {({ handleSubmit, setFieldValue, errors, values, setValues }) => (
              <Form>
                <div className="form-section">
                  <CustomInput
                    label={"Fundraiser Name"}
                    name="name"
                    className={"input"}
                  />
                  <CustomInput
                    label={"Address"}
                    name="address"
                    className={"input"}
                  />
                  <CustomInput label={"City"} name="city" />

                  <Grid container spacing={1}>
                    <Grid item sm={12} md={6}>
                      <CustomInput label={"State"} name="state" />
                    </Grid>
                    <Grid item sm={12} md={6}>
                      <CustomInput label={"Zip Code"} name="zipCode" />
                    </Grid>
                  </Grid>

                  <CustomInput
                    label={"501(C)3 (optional)"}
                    name="taxId"
                    className={"input"}
                  />
                  {/* <CustomInput
              label={"Paragraph 1"}
              name="para1"
              className={"input"}
            />
            <CustomInput
              label={"Paragraph 2"}
              name="para2"
              className={"input"}
            /> */}
                  <Grid container spacing={1}>
                    <Grid item sm={6} md={6}>
                      <CustomInput
                        label={"Start Date"}
                        name="startDate"
                        type="date"
                        value={"28-12-2024"}
                        className={"customDate"}
                        onChange={(e) =>
                          handleStartDateChange(e, setValues, values)
                        }
                      />
                    </Grid>
                    <Grid item sm={6} md={6}>
                      <CustomCounter
                        label={"Duration"}
                        name="endDate"
                        className={!isMd && "info-modal-counter"}
                        onChange={(value) => {
                          handleCounterChange(setValues, values, value);
                        }}
                        initialValue={() => {
                          try {
                            return calculateWeeks(
                              initialValues?.startDate,
                              initialValues?.endDate
                            );
                          } catch {
                            return 3;
                          }
                        }}
                        max={4}
                      />
                    </Grid>
                  </Grid>

                  <div className="direct-deposit"></div>

                  <div className="condition-list">
                    <FormGroup>
                      <FormControlLabel
                        checked={values.checks.isAuthorizedAdmin}
                        onChange={(event) =>
                          handleCheckBoxChange(
                            setValues,
                            values,
                            "isAuthorizedAdmin",
                            event.target.checked
                          )
                        }
                        typeof="checkbox"
                        required
                        control={<CustomCheckBox />}
                        label={
                          <p className="para">
                            I am an authorized administrator of the Organization
                          </p>
                        }
                      />
                      <FormControlLabel
                        checked={values.checks.isPrivacyPolicy}
                        onChange={(event) =>
                          handleCheckBoxChange(
                            setValues,
                            values,
                            "isPrivacyPolicy",
                            event.target.checked
                          )
                        }
                        typeof="checkbox"
                        required
                        control={<CustomCheckBox />}
                        label={
                          <p className="para">
                            I have read & agree to
                            <span className="link">Terms and condition</span>
                          </p>
                        }
                      />
                      <FormControlLabel
                        checked={values.checks.isTerms}
                        onChange={(event) =>
                          handleCheckBoxChange(
                            setValues,
                            values,
                            "isTerms",
                            event.target.checked
                          )
                        }
                        typeof="checkbox"
                        required
                        control={<CustomCheckBox />}
                        label={
                          <p className="para">
                            I have read & agree to
                            <span className="link">Privacy and policy</span>
                          </p>
                        }
                      />
                    </FormGroup>
                  </div>

                  <div className="button-group">
                    {loader ? (
                      <Spinner />
                    ) : (
                      <CustomButton
                        title={"Submit"}
                        onClick={handleSubmit}
                        className={"submit-button"}
                        disabled={Object.keys(errors).length > 0}
                      />
                    )}
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </CustomDialog>
  );
};

export default FundraiserInfoModal;
