import axios from "../AxiosInstance";

export const participantSignUp = (body) =>
  new Promise((resolve, reject) => {
    axios
      .post(`/participantRoutes/participantSignUp`, body)
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });

export const getParticipantDashboardByFundRaisingId = (fundRaisingId) =>
  new Promise((resolve, reject) => {
    axios
      .get(
        `/participantRoutes/getParticipantDashboardByFundRaisingId?fundRaisingId=${fundRaisingId}`
      )
      .then((response) => {
        resolve(response.data.data);
      })
      .catch((err) => {
        reject(err);
      });
  });

export const getParticipantFundraisings = () =>
  new Promise((resolve, reject) => {
    axios
      .get(`/participantRoutes/getParticipantFundRaisings`)
      .then((response) => {
        resolve(response.data.data);
      })
      .catch((err) => {
        reject(err);
      });
  });

export const getParticipants = (fundRaisingId) =>
  new Promise((resolve, reject) => {
    axios
      .get(
        `/participantRoutes/fundRaisingParticipants?fundRaisingId=${fundRaisingId}`
      )
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });

export const requestToJoinNewFundRaising = (fundRaisingId) =>
  new Promise((resolve, reject) => {
    const body = { fundRaisingId };
    axios
      .put("/participantRoutes/requestToJoinNewFundRaising", body)
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });

export const addNewContact = (body) =>
  new Promise((resolve, reject) => {
    axios
      .post("/contactRoutes/addContact", body)
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => {
        //console.log(err);
        reject(err);
      });
  });

export const updateContact = (body) =>
  new Promise((resolve, reject) => {
    axios
      .put("/contactRoutes/updateContact", body)
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => {
        //console.log(err);
        reject(err);
      });
  });

export const deleteContact = (contactId) =>
  new Promise((resolve, reject) => {
    axios
      .delete("/contactRoutes/deleteContact?contactId=" + contactId)
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => {
        //console.log(err);
        reject(err);
      });
  });

export const generateTeammemberInvitationLink = (body) =>
  new Promise((resolve, reject) => {
    axios
      .post("/participantRoutes/generateTeamMemberInvitationLink", body)
      .then((response) => {
        resolve(response.data.data);
      })
      .catch((err) => {
        //console.log(err);
        reject(err);
      });
  });

export const generateGeneralTeammemberInvitationLink = (fundRaisingId) =>
  new Promise((resolve, reject) => {
    axios
      .post("/participantRoutes/generateTeamMemberInvitationLink", {
        isGeneral: true,
        fundRaisingId,
      })
      .then((response) => {
        resolve(response.data.data);
      })
      .catch((err) => {
        reject(err);
      });
  });

export const generateParticipantGroupDonationLink = (groupId) =>
  new Promise((resolve, reject) => {
    axios
      .put("/participantRoutes/generateParticipantGroupDonationLink", {
        groupId,
      })
      .then((response) => {
        resolve(response.data.data);
      })
      .catch((err) => {
        reject(err);
      });
  });

export const shareTokensToTeammember = (body) =>
  new Promise((resolve, reject) => {
    axios
      .put("/participantRoutes/sendTokensToTeamMember", body)
      .then((response) => {
        resolve(response.data.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
