import React, { useState } from "react";
import ".././styles.scss";
import CustomTable from "../../../../common/CustomTable";
import "react-datepicker/dist/react-datepicker.css";
import CustomButton from "../../../../common/CustomButton";
import CustomDialog from "../../../../common/CustomDialog";

const SimilarFundraisersModal = ({
  fundraiserData = null,
  onSubmit,
  loader,
  updateFundraiserData,
  open,
  onClose,
  onBack,
}) => {
  const [selection, setSelection] = useState(null);

  const onSelection = (index) => {
    setSelection(index);
  };

  const groups = fundraiserData?.similarFundraisers?.data || [];

  const similarFundraisersRows = groups;
  const similarFundraisersColumn = [
    { name: "Name", dataIndex: "fundRaisingName" },
    { name: "Address", dataIndex: "address" },
  ];

  return (
    <CustomDialog
      open={open}
      onClose={onClose}
      title={"Fundraiser With Similar Name or Address"}
      onBackButtonClick={onBack}
      closeOnIconOnly
    >
      <div className="similar-fundraisers-modal">
        <CustomTable
          columns={similarFundraisersColumn}
          loader={loader}
          rows={similarFundraisersRows}
          isSelection={true}
          onSelect={onSelection}
          maxHeight={800}
        />
        <div className="button-group">
          <CustomButton
            title={"Select"}
            disabled={loader || selection === null}
          ></CustomButton>
          <CustomButton
            title={"New Fundraiser"}
            onClick={onSubmit}
            disabled={loader}
          ></CustomButton>
        </div>
      </div>
    </CustomDialog>
  );
};

export default SimilarFundraisersModal;
