import React, { useEffect, useState } from "react";
import CustomButton from "../../../common/CustomButton";
import CustomTable from "../../../common/CustomTable";
import { Toaster } from "../../../../constants";
import {
  approveAdmin,
  getPendingApprovalAdmins,
  inviteAdmin,
  GetAdminFundRaisings,
  getPendingApprovalParticipants,
  getAdminStatus,
} from "../../../../apis/Admin";
import PermissionModal from "./PermissionModal";
import Fundraiser from "./Fundraiser";
import ApprovedAdmins from "./ApprovedAdmins";
import { useSelector } from "react-redux";
import "./styles.scss";
import CustomDialog from "../../../common/CustomDialog";
import { MdOutlineContentCopy } from "react-icons/md";
import copy from "copy-to-clipboard";
import { LuCopyCheck } from "react-icons/lu";
import NewFundraiserModalV2 from "../Dashboard/NewFundraiserModalV2";
import FundraiserSelectorModal from "./FundraiserSelectorModal";
import { useNavigate } from "react-router-dom";

const Dashboard = () => {
  const pendingApprovalsColumn = [
    { name: "First Name", dataIndex: "firstName" },
    { name: "Last Name", dataIndex: "lastName" },
    { name: "Email", dataIndex: "email" },
    { name: "Phone Number", dataIndex: "phone" },
  ];

  const [adminApprovalList, setAdminApprovalList] = useState([]);
  const [adminApprovalLoader, setAdminApprovalLoader] = useState(false);

  const [openPermissionModal, setOpenPermissionModal] = useState(false);
  const [isGetAdminApprovals, setIsGetAdminApprovals] = useState(false);
  const [openlinkDialog, setOpenLinkDialog] = useState(false);
  const [getInvitationLink, setGetInvitationLink] = useState([]);
  // const [dashboard, setDashboard] = useState(null);
  const [copied, setCopied] = useState(false);
  const [isApproved, setIsApproved] = useState(true);

  const [openNewFundraiser, setOpenNewFundraiser] = useState(false);
  const [adminFundraisings, setAdminFundraisings] = useState(null);

  const [selectedFundraising, selectFundraising] = useState(null);

  const [fundraiserSelectorModal, showFundraiserSelectorModal] =
    useState(false);

  // const [openFundraiserModal, setFundraiserModal] = useState(false);

  const CloseFundraiserSelectorModal = () => {
    showFundraiserSelectorModal(false);
  };

  const OpenFundraiserSelectorModal = () => {
    if (!adminFundraisings)
      return Toaster("warning", "Wait for previous fundraisers to load!");
    if (!adminFundraisings?.length)
      return Toaster("warning", "You do not have any previous fundraisers!");
    if (openNewFundraiser) setOpenNewFundraiser(false);
    showFundraiserSelectorModal(true);
  };

  const auth = useSelector((state) => state.auth.me);

  const fetchFundraisingsData = async () => {
    if (adminFundraisings) return;
    const data = await GetAdminFundRaisings();
    setAdminFundraisings(
      data.sort(
        (a, b) =>
          Math.abs(Date.now() - parseInt(a.fundRaisingStartTime)) -
          Math.abs(Date.now() - parseInt(b.fundRaisingStartTime))
      )
    );
  };

  const fetchAdminStatus = async () => {
    if (auth?.role === "secondaryAdmin") {
      const data = await getAdminStatus();
      setIsApproved(data.adminApproval);
    }
  };

  useEffect(
    () => {
      if (!auth) return;
      fetchAdminStatus();
      fetchFundraisingsData();
    },

    // eslint-disable-next-line
    [auth]
  );

  const handleGetAdminApprovals = async () => {
    try {
      setAdminApprovalLoader(true);
      let response = await getPendingApprovalAdmins();
      response = response.data;
      let tempArray =
        response.length === 0
          ? []
          : response.map((admin) => {
              return {
                firstName: admin?.adminId?.firstName,
                lastName: admin?.adminId?.lastName,
                email: admin?.adminId?.email,
                phone: admin?.adminId?.phoneNo,
                // startNewFundraiser: admin?.privileges?.startNewFundraiser,
                ...admin,
              };
            });
      setAdminApprovalList(tempArray);
      setAdminApprovalLoader(false);
    } catch (error) {
      setAdminApprovalLoader(false);
      let errorMessage =
        error.response?.data?.message ||
        "Admin pending approvals could not be loaded";
      Toaster("error", errorMessage);
    }
  };

  // const handleCloseFundraiserModal = () => {
  //   setFundraiserModal(false);
  // };

  const handleAdminApprove = async (value) => {
    try {
      setAdminApprovalLoader(true);
      await approveAdmin(value?.adminId?._id);
      await handleGetAdminApprovals();
      setIsGetAdminApprovals(true);
      Toaster("success", "Admin has been approved successfully");
    } catch (error) {
      setAdminApprovalLoader(false);
      let errorMessage =
        error.response?.data?.message || "Admin could not be approved";
      Toaster("error", errorMessage);
    }
  };

  const handleOpenPermissionModal = () => {
    setOpenPermissionModal(true);
  };

  const handleClosePermissionModal = () => {
    setOpenPermissionModal(false);
  };

  const handleInviteAdmin = async (values) => {
    try {
      const invitationCode = await inviteAdmin({
        ...values,
        fundRaiserId: selectedFundraising,
      });
      Toaster("success", "Invite link has been sent successfully");
      setGetInvitationLink(
        process.env.REACT_APP_BASE_URL + "admin/signup/" + invitationCode
      );
      setOpenLinkDialog(true);
    } catch (error) {
      let errorMessage =
        error.response?.data?.message || "Invite link could not be sent.";
      Toaster("error", errorMessage);
      setOpenLinkDialog(false);
    }
  };

  // const handleLoadDashboardByFundraiserId = async (values) => {
  //   try {
  //     let data = await getDashboard(values?.fundraiser);
  //     setDashboard(data);
  //   } catch (error) {
  //     let errorMessage =
  //       error.response?.data?.message || "Dashboard could not be loaded";
  //     Toaster("error", errorMessage);
  //     throw new Error(errorMessage);
  //   }
  // };

  // const handlePrimaryAdminDashboard = async () => {
  //   try {
  //     let response = await GetPrimaryAdminDashboard();
  //     setDashboard(response.data);
  //   } catch (error) {
  //     let errorMessage =
  //       error.response?.data?.message || "Dashboard could not be loaded";
  //     Toaster("error", errorMessage);
  //   }
  // };
  //getApprovalAdmins............
  // const getApprovalAdmins = async () => {
  //   try {
  //     let response = await inviteAdmin();
  //     let tempArray = response.data;
  //     setGetInvitationLink(tempArray);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };
  const copyToClipboard = () => {
    copy(`${window.location.origin}/participant/signup/${getInvitationLink}`);
    setCopied(true);
  };

  // useEffect(() => {
  //   if (auth?.privileges?.startNewFundraiser && auth?.role === "admin")
  //     getApprovalAdmins();
  // }, [auth]);

  useEffect(() => {
    if (auth && auth?.role === "admin") {
      handleGetAdminApprovals();
      // handlePrimaryAdminDashboard();
    }
  }, [auth]);

  const navigate = useNavigate();

  if (auth?.role === "admin")
    return (
      <div className="admin-dashboard-wrapper">
        <div className="title">Primary Admin Dashboard</div>
        {/* <div className="new-fundraiser-button-wrapper">
        <CustomButton title={"Start New Fundraiser"} disabled />
      </div> */}

        <div className="stats-wrapper">
          <div className="stat-one-wrapper section">
            <div className="stat-title">Account Information</div>
            <div className="stat">
              First Name:
              <span>{auth.firstName || "---"}</span>
            </div>
            <div className="stat">
              Last Name:
              <span>{auth.lastName || "---"}</span>
            </div>
            <div className="stat">
              Email: <span>{auth.email || "---"}</span>
            </div>
            <div className="stat">
              Phone Number: <span>{auth.phoneNo || "---"}</span>
            </div>
            <div
              className="text-with-link"
              onClick={() => navigate("/admin/stripeAccounts")}
            >
              Manage Stripe Accounts
            </div>
          </div>
          {/* {auth && (
            <>
              <div className="stat-two-wrapper section">
                <div className="stat-title">Selected Fundraiser</div>
                <div className="stat">
                  Name:{" "}
                  <span>{dashboard?.fundRaiser?.fundRaisingName || "---"}</span>
                </div>
                <div className="stat">
                  Total Funds:
                  <span>
                    {Number(dashboard?.fundRaiser?.totalFunding) || "00.0"}
                  </span>
                </div>
                <div className="stat">
                  City: <span>{dashboard?.fundRaiser?.city || "---"}</span>
                </div>
                <div className="stat">
                  State: <span>{dashboard?.fundRaiser?.state || "---"}</span>
                </div>
                <div className="stat">
                  Zip: <span>{dashboard?.fundRaiser?.zip || "---"}</span>
                </div>
                <div className="stat">
                  Goal:{" "}
                  <span>{dashboard?.fundRaiser?.fundRaisingGoal || "---"}</span>
                </div>
              </div>
            </>
          )} */}
          {/* {auth && (
            <div className="new-fundraising-button-wrapper">
              <Fundraiser adminApprovalList={adminApprovalList} />
            </div>
          )} */}

          {/* {auth  && (
            <div className="stat-two-wrapper section">
              <div className="stat-title">Fundraiser Stats</div>
              <div className="stat">
                Total Fundraisers:{" "}
                <span>{dashboard?.totalFundRaisings || "---"}</span>
              </div>
              <div className="stat">
                Total Secondary Admins:{" "}
                <span>{dashboard?.totalSecondaryAdmins || "---"}</span>
              </div>
              <div className="stat">
                Total Groups:
                <span>{dashboard?.totalGroups || "---"}</span>
              </div>
              <div className="stat">
                Total Participants:
                <span>{Number(dashboard?.totalParticipants) || "00.0"}</span>
              </div>

              <div className="stat">
                Total Team Members:{" "}
                <span>{dashboard?.totalTeamMembers || "---"}</span>
              </div>
            </div>
          )} */}
        </div>
        {auth && (
          <>
            <div className="admin-sub-section-wrapper">
              <div className="admin-sub-title">Pending Admin Approvals</div>

              <div className="admin-approval-list-wrapper">
                <CustomTable
                  columns={pendingApprovalsColumn}
                  loader={adminApprovalLoader}
                  rows={adminApprovalList}
                  maxHeight={300}
                  isApprovals
                  onApprove={handleAdminApprove}
                />
              </div>
              <div className="admin-invite-button-wrapper">
                <CustomButton
                  title={"Invite New Admin"}
                  onClick={() => showFundraiserSelectorModal(true)}
                />
              </div>
            </div>

            <ApprovedAdmins isGetAdminApprovals={isGetAdminApprovals} />
          </>
        )}

        {/* {auth && dashboard && (
          <Groups
            groups={dashboard?.group}
            fundraiser={dashboard?.fundRaiser}
          />
        )}
        {auth && (
          <>
            <Sponsers />
            <Donors />
          </>
        )} */}

        <FundraiserSelectorModal
          fundraiserData={adminFundraisings}
          open={fundraiserSelectorModal}
          onClose={CloseFundraiserSelectorModal}
          onSubmit={(fundraiserId) => {
            selectFundraising(fundraiserId);
            showFundraiserSelectorModal(false);
            handleOpenPermissionModal();
          }}
          title={"Select Fundraising to invite admin"}
        />
        <PermissionModal
          open={openPermissionModal}
          onClose={handleClosePermissionModal}
          title="Invite New Admin "
          onSubmit={handleInviteAdmin}
        />
        <Fundraiser />
        {/* <FundraiserModal
          open={openFundraiserModal}
          onClose={handleCloseFundraiserModal}
          title="Fundraiser"
          onSubmit={handleLoadDashboardByFundraiserId}
        /> */}
        <CustomDialog
          open={openlinkDialog}
          onClose={() => setOpenLinkDialog(false)}
          title={"Invite Link"}
        >
          <div className="invitaion-link-warpper">
            <div>
              <h4 className="secondary-admin-link">{getInvitationLink}</h4>
            </div>
            <div className="icon-wrapper">
              {!copied ? (
                <MdOutlineContentCopy
                  className="icon"
                  onClick={copyToClipboard}
                />
              ) : (
                <LuCopyCheck className="icon" />
              )}
            </div>
          </div>
        </CustomDialog>
      </div>
    );
  else
    return (
      <div className="admin-dashboard-wrapper">
        {fundraiserSelectorModal && (
          <FundraiserSelectorModal
            fundraiserData={adminFundraisings}
            open={fundraiserSelectorModal}
            onClose={CloseFundraiserSelectorModal}
            onSubmit={(fundraiserId) =>
              navigate(`/admin/dashboard/${fundraiserId}`)
            }
            title="Select fundraiser"
          />
        )}
        <div className="title">Administrator Dashboard</div>
        {/* <div className="new-fundraiser-button-wrapper">
      <CustomButton title={"Start New Fundraiser"} disabled />
    </div> */}

        <div className="stats-wrapper-centered">
          <div className="stat-one-wrapper section">
            <div className="stat-title">Information</div>
            <div className="stat">
              Name:
              <span>{auth?.firstName || "---"}</span>
              <span>{auth?.lastName || "---"}</span>
            </div>
            <div className="stat">
              Email: <span>{auth?.email || "---"}</span>
            </div>
            <div className="stat">
              Contact: <span>{auth?.phoneNo || "---"}</span>
            </div>
          </div>
        </div>
        {isApproved ? (
          <div className="secondary-admin-buttons">
            <CustomButton
              className="customBtn"
              title="Start New Fundraiser"
              onClick={() => {
                // if (!auth?.privileges?.startNewFundraiser)
                //   return Toaster(
                //     "error",
                //     "You do not have access to create new fundraiser!"
                //   );
                setOpenNewFundraiser(true);
              }}
            />
            <CustomButton
              className="customBtn"
              title="Previous Fundraiser Information"
              disabled={!adminFundraisings}
              onClick={() => {
                if (!adminFundraisings?.length)
                  return Toaster(
                    "warning",
                    "You do not have any previous fundraisers!"
                  );
                OpenFundraiserSelectorModal();
              }}
            />
            <CustomButton
              className="customBtn hide-on-mobile"
              title="Manage Stripe Accounts"
              onClick={() => navigate("/admin/stripeAccounts")}
            />
            <div
              className="navigate-text margin-top"
              onClick={() => navigate("/admin/stripeAccounts")}
            >
              Manage Stripe Accounts
            </div>
          </div>
        ) : (
          <div className="verification-in-progress-text">
            ⚠️ Verification in progress... Please wait for primary admin to
            approve.
          </div>
        )}
        <NewFundraiserModalV2
          open={openNewFundraiser}
          onClose={() => {
            setOpenNewFundraiser(false);
          }}
          onSubmit={() => {}}
          previousFundraiserData={adminFundraisings}
          openPreviousFundraiserSelector={OpenFundraiserSelectorModal}
        />
      </div>
    );
};

export default Dashboard;
