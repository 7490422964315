import React from "react";
import "react-datepicker/dist/react-datepicker.css";
import CustomDialog from "./CustomDialog";
import CustomButton from "./CustomButton";
import Spinner from "./Spinner";
import CustomTable from "./CustomTable";

const LeaderboardModal = ({ open, onClose, title = "Leaderboard", data }) => {
  const columns = [
    { name: "Rank", dataIndex: "rank" },
    { name: "First Name", dataIndex: "firstName" },
    { name: "Last Name", dataIndex: "lastName" },
    { name: "Score", dataIndex: "score" },
  ];

  const rows = [
    {
      rank: "#1",
      firstName: "Carly",
      lastName: "Brown",
      score: 1050,
    },
    {
      rank: "#2",
      firstName: "Jake",
      lastName: "Smith",
      score: 1020,
    },
    {
      rank: "#3",
      firstName: "Mia",
      lastName: "Johnson",
      score: 1000,
    },
    {
      rank: "#4",
      firstName: "Ethan",
      lastName: "Taylor",
      score: 980,
    },
    {
      rank: "#5",
      firstName: "Ava",
      lastName: "Davis",
      score: 960,
    },
    {
      rank: "#6",
      firstName: "Sophia",
      lastName: "Williams",
      score: 940,
    },
    {
      rank: "#7",
      firstName: "Noah",
      lastName: "Garcia",
      score: 920,
    },
    {
      rank: "#8",
      firstName: "Oliver",
      lastName: "Martinez",
      score: 900,
    },
    {
      rank: "#9",
      firstName: "Emily",
      lastName: "Rodriguez",
      score: 880,
    },
    {
      rank: "#10",
      firstName: "Liam",
      lastName: "Lopez",
      score: 860,
    },
  ];

  return (
    <CustomDialog open={open} onClose={onClose} title={title}>
      <div className="leaderboard-modal">
        <ul className="leaderboard-list">
          {rows.map((row, index) => (
            <li
              key={index}
              className={`leaderboard-item ${
                index === 0
                  ? "first-place"
                  : index === 1
                  ? "second-place"
                  : index === 2
                  ? "third-place"
                  : ""
              }`}
            >
              <div className="rank">{row.rank}</div>
              <div className="details">
                <h3>
                  {row.firstName} {row.lastName}
                </h3>
                <p>Score: {row.score}</p>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </CustomDialog>
  );
};

export default LeaderboardModal;
